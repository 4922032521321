.gsd-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 3rem;
}

.gsd-header__logo {
  height: 7rem;
}

.gsd-header__invoice-number:hover {
  cursor: pointer;
}
