.gsd-body {
  font-family: Roboto;
}

.gsd-body--small {
  font-size: .75rem;
}

.gsd-body--xsmall {
  font-size: .5rem;
}

.gsd-body--right {
  text-align: right;
}

.gsd-body--no-margin {
  margin: 0;
}
