.gsd-table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 2rem;
}

.gsd-table__row--odd {
  background-color: #ddd;
}

.gsd-table__header {
  font-family: 'DIN Alternate';
  font-weight: 700;
  padding: 0 .5rem;
}

.gsd-table__header--right {
  text-align: right;
}

.gsd-table__header--full {
  width: 100%;
}

.gsd-table__row--item:hover {
  background-color: #22F7F4;
  color: #000;
  cursor: pointer;
}

.gsd-table__row--add-item {
  background-color: #FE2735;
  color: #fff;
  text-align: center;
}

.gsd-table__row--add-item:hover {
  background-color: #22F7F4;
  color: #000;
  cursor: pointer;
}

.gsd-table__cell {
  font-family: Roboto;
  padding: 0 .5rem;
}

.gsd-table__cell--right {
  text-align: right;
}

.gsd-table__foot {
  border-top: 2px solid #000;
}
