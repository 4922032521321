.gsd-heading {
  font-family: 'DIN Alternate';
  font-weight: 700;
  margin: 0;
  line-height: 1;
}

.gsd-heading--l {
  font-size: 4rem;
}

.gsd-heading--m {
  font-size: 1.5rem;
}

.gsd-heading--s {
  font-size: 1rem;
}

.gsd-heading--spaced {
  line-height: 1.3;
}

.gsd-heading--caps {
  text-transform: uppercase;
}

.gsd-heading--right {
  text-align: right;
}
