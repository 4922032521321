@font-face {
  font-family: 'DIN Alternate';
  src: url('/fonts/din-alternate/DIN-Alternate-Bold.woff');
  font-weight: 700;
}
@font-face {
  font-family: 'Roboto';
  src: url('/fonts/roboto/Roboto-Light.woff');
  font-weight: 300;
}
@font-face {
  font-family: 'Roboto';
  src: url('/fonts/roboto/Roboto-LightItalic.woff');
  font-weight: 300;
  font-style: italic;
}

.gsd-template {
  font-size: 16px;
  box-sizing: border-box;
  line-height: 1.6;
  height: 100%;
}

.gsd-template * {
  box-sizing: border-box;
}

.gsd-template__body {
  margin: 0;
  min-height: 100%;
  display: flex;
}

.gsd-root {
  display: flex;
  width: 100%;
}

@media screen {
  .gsd-root {
    padding: 1rem;
  }
}

@media print {
  [class$="--ui"] {
    display: none;
  }
}
