.gsd-client-details {
  margin-bottom: 2rem;
}

.gsd-client-details__heading {
  background-color: #000;
  color: #fff;
  padding: .25rem .5rem;
  margin-bottom: .25rem;
  display: inline-block;
}

.gsd-client-details__details:hover {
  cursor: pointer;
}
