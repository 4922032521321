.gsd-details {
  display: flex;
  justify-content: space-between;
  margin-bottom: 2rem;
}

.gsd-details__company-info {
  flex: 1;
}

.gsd-details__invoice-info {
  flex: 1;
  text-align: right;
}

.gsd-details__date-issued:hover {
  cursor: pointer;
}
